import React from "react"
import { graphql } from "gatsby"
import Img from "gatsby-image"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Customers from "../components/customers"
import ActionForm from "../components/action-form"

const Contact = ({ data }) => (
  <Layout>
    <SEO
      title="Request a Demo, Contact Us - Celantur"
      description="We're happy to help you in English, Deutsch and 汉语."
    />

    <section className="container pt-5 my-5 text-center">
      <h1>Contact Us</h1>
      <p className="lead">
        We're happy to help you in English, Deutsch and 汉语.
        <br />
        Please tell us a bit about your use case.
      </p>
    </section>
    <section className="container pt-5 my-5">
      <div className="row">
        <div className="col-12 col-lg-6">
          <h2 className="mb-5 text-center">Send a Message</h2>
          <ActionForm labelSubmitButton="Send" />
        </div>
        <div className="col-12 col-lg-6">
          <h2 className="text-center">Book a meeting</h2>

          <div className="card">
            <div className="row align-items-center">
              <div className="col-auto">
                <div className="avatar avatar-xxl p-3">
                  <Img fixed={data.imageAlex.childImageSharp.fixed} alt="..." className="avatar-img rounded" />
                </div>
              </div>
              <div className="col p-0">
                Alexander Petkov
                <br />
                CEO
              </div>
              <div className="col-auto ms-auto">
                <a
                  href="https://calendar.google.com/calendar/u/0/appointments/schedules/AcZssZ22odPww4oGNEuKCtedwPenyc3TN61pgy_hLUc3I__oRnaycvnNl5o3bS7zUAjnBXOkQKr5f3F8"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="btn btn-sm btn-primary-soft stretched-link"
                >
                  Book Meeting
                </a>
              </div>
            </div>

          </div>
        </div>
      </div>
    </section>

    <section className="py-5">
      <div className="container">
        <div className="row text-center">
          <div className="col-12 col-md">
            <hr className="hr-md mb-7" />
            <blockquote className="blockquote mb-7">
              <p className="font-size-xl mb-5 text-center text-primary-desat">
                "Celantur has helped us win well-known customers for our mobile mapping business. <br />
                Their solution has made us and our users completely convinced.
                <br />
                The cooperation with Celantur is always goal-oriented and easy-going. <br />
                <strong>Simply the best solution on the market!</strong>"
              </p>

              <p className="font-size-lg font-weight-bold mb-0">Dipl.Ing. (FH) Wolfgang Probst</p>
              <p className="font-size-sm text-muted mb-0">CEO of AllTerra DS</p>
            </blockquote>
            <hr className="hr-md mb-7" />
          </div>
        </div>
      </div>
    </section>

    <Customers />
  </Layout>
)

export default Contact

export const query = graphql`
  fragment portrait on File {
    childImageSharp {
      fixed(width: 64, height: 64) {
        ...GatsbyImageSharpFixed
      }
    }
  }
  query {
    imageAlex: file(relativePath: { eq: "team/alex.jpg" }) {
      ...portrait
    }
  }
`
